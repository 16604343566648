* {
  box-sizing: border-box;
}

html {
  margin: 0;
}

body {
  overflow-x: hidden;
}

footer {
  
}

h1 {
  color: #1e2e62;
  margin-top: 0;
  @media (min-width: 768px) {
    font-size: 32px;
  }
  @media (min-width: 1440px) {
    font-size: 36px;
  }
}

p {
  color: #1e2e62;
  @media (min-width: 1440px) {
    font-size: 20px;
  }
}

.section {
  padding: 60px 0;
  @media (min-width: 1440px) {
    padding: 100px 0;
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 375px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.white {
  color: white;
}

.small {
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
}


/*CORNER EDGES*/

.horizontal {
  height: 8px;
  width: 50%;
  background: #8ac53f;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 10px;
  @media (min-width: 768px) {
    height: 15px;
  }
  @media (min-width: 1440px) {
    height: 20px;
  }
}

.vertical {
  height: 50%;
  width: 8px;
  background: #8ac53f;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 10px;
  @media (min-width: 768px) {
    width: 15px;
  }
  @media (min-width: 1440px) {
    width: 20px;
  }
}

.bottomHorizontal {
  height: 8px;
  width: 50%;
  background: #66cce8;
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 10px;
  @media (min-width: 768px) {
    height: 15px;
  }
  @media (min-width: 1440px) {
    height: 20px;
  }
}

.bottomVertical {
  height: 50%;
  width: 8px;
  background: #66cce8;
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 10px;
  @media (min-width: 768px) {
    width: 15px;
  }
  @media (min-width: 1440px) {
    width: 20px;
  }
}

/*CORNER EDGES*/

/*INVERTED CORNER EDGES*/

.horizontalInverted {
  height: 8px;
  width: 50%;
  background: #8ac53f;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 10px;
  @media (min-width: 768px) {
    height: 15px;
  }
  @media (min-width: 1440px) {
    height: 20px;
  }
}

.verticalInverted {
  height: 50%;
  width: 8px;
  background: #8ac53f;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 10px;
  @media (min-width: 768px) {
    width: 15px;
  }
  @media (min-width: 1440px) {
    width: 20px;
  }
}

.bottomHorizontalInverted {
  height: 8px;
  width: 50%;
  background: #66cce8;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 10px;
  @media (min-width: 768px) {
    height: 15px;
  }
  @media (min-width: 1440px) {
    height: 20px;
  }
}

.bottomVerticalInverted {
  height: 50%;
  width: 8px;
  background: #66cce8;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 10px;
  @media (min-width: 768px) {
    width: 15px;
  }
  @media (min-width: 1440px) {
    width: 20px;
  }
}

/*INVERTED CORNER EDGES*/
