header {
  position: relative;
  padding: 10px 0 30px 0;
  border-bottom: 10px solid #80808069;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.topBar {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1% 3%;
}

.social {
  width: 85px;
  display: flex;
  justify-content: space-around;
  @media (min-width: 768px) {
    width: 100px;
  }
  @media (min-width: 1440px) {
    width: 120px;
  }
}

.icon {
  width: 15px;
  @media (min-width: 768px) {
    width: 20px;
  }
  @media (min-width: 1440px) {
    width: 25px;
  }
  &:hover {
    width: 30px;
    transition: 0.5s;
  }
}

.language {
  width: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width:768px) {
    width: 65px;
  }
  @media (min-width:1440px) {
    width: 75px;
  }
}

.flag {
  width: 15px;
  @media (min-width: 768px) {
    width: 25px;
  }
  @media (min-width: 1440px) {
    width: 30px;
  }
  &:hover {
    width: 35px;
    transition: 0.5s;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;
}

// .logo {
//   width: 100%;
//   max-width: 115px;
//   @media (min-width:768px) {
// 		max-width: 165px;
//   }
//   @media (min-width:1440px) {
// 		max-width: 250px;
// 	}
// }

h1 {
  margin-bottom: 0;
}

.green {
  color: #8ac53f;
}

.blue {
  color: #66cce8;
}
