.image {
  width: 80%;
  @media (min-width: 768px) {
    max-width: 385px;
  }
}

.displayNone {
  // visibility: hidden;
  width: 80%;
  @media (min-width: 768px) {
    max-width: 385px;
  }
}
