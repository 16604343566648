footer {
    position: relative;
    background: #80808012;
}

.footerText {
  margin: 0;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: gray;
  font-size: 10px;
  @media (min-width: 768px) {
    font-size: 14px;
    right: 20px;
  }
  @media (min-width: 1440px) {
    font-size: 16px;
    right: 35px;
    bottom: 20px;
  }
}
