.section {
  background: #80808012;
  padding: 60px 0;
  @media (min-width: 1440px) {
    padding: 100px 0;
  }
}

.wrapper {
  position: relative;
  background: white;
  padding: 50px 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width:768px) {
    padding: 60px 35px;
  }
  @media (min-width:1440px) {
      flex-direction: row;
      justify-content: space-between;
      // align-items: flex-start;
      padding: 75px;
  }
}

.textContainer {
  text-align: center;
  @media (min-width:1440px) {
      text-align: left;
      width: 60%;
  }
}

.headline {
}

.text {
  text-indent: 10%;
  text-align: justify;
}

.imageContainer {
  display: flex;
  justify-content: center;
  order: -1;
  margin-bottom: 35px;
  @media (min-width:1440px) {
      width: 30%;
      margin-bottom: 0;
  }
}

.image {
  width: 70%;
  height: 100%;
  @media (min-width: 576px) {
    width: 50%;
  }
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 1440px) {
    width: 100%;
  }
}
