.section {
  background: #80808012;
  padding: 60px 0;
  @media (min-width: 1440px) {
    padding: 100px 0;
  }
}

.wrapper {
  position: relative;
  background: white;
  padding: 50px 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  @media (min-width: 768px) {
    padding: 65px;
  }
  @media (min-width: 1440px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 75px;
  }
}

// #horizontal {
//   width: 70px;
// }

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ribbonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  margin-bottom: 30px;
  @media (min-width:768px) {
    margin-bottom: 45px;
  }
}

.text {
  text-indent: 10%;
  text-align: justify;
}

.ribbon {
  width: 100%;
  margin: 30px 0;
  @media (min-width: 768px) {
    width: 70%;
    margin: 45px 0;
  }
}

.button {
  background: #8ac53f;
  border: none;
  padding: 15px 30px;
  color: white;
  border-radius: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 800;
  letter-spacing: 2px;
  text-decoration: none;
  margin-bottom: 10px;
  cursor: pointer;
  @media (min-width:768px) {
    margin-bottom: 15px;
  }
  &:hover {
    background: #8ac53fcc;
    transition: ease-in-out 1s;
  }
}
