.section {
    background: #80808012;
    padding: 60px 0;
    @media (min-width: 1440px) {
      padding: 100px 0;
    }
  }
  
  .wrapper {
    position: relative;
    background: white;
    padding: 50px 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 768px) {
      padding: 65px;
    }
    @media (min-width: 1440px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 75px;
    }
  }
  
  // #horizontal {
  //   width: 70px;
  // }
  
  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 45px;
    @media (min-width: 1440px) {
      width: 50%;
      margin-bottom: 0;
      align-items: flex-start;
      text-align: left;
    }
  }

  .text {
    text-indent: 10%;
    text-align: justify;
  }

  a {
      text-decoration: none;
      color: inherit;
  }

  .imageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 1440px) {
      width: 50%;
    }
  }
  
  .image {
    width: 100%;
    max-width: 215px;
    @media (min-width: 1440px) {
      max-width: 285px;
    }
  }
