.section {
  background: #80808012;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  @media (min-width: 1440px) {
    padding: 100px 0;
  }
}

.wrapper {
  background: white;
  padding: 50px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width:768px) {
    padding: 65px;
  }
  @media (min-width: 1440px) {
    padding: 75px;
    flex-direction: row;
    // align-items: flex-start;
    justify-content: space-between;
  }
}

.textWrapper {
  margin-bottom: 45px;
  text-align: center;
  @media (min-width: 768px) {
    // padding: 0 65px;
  }
  @media (min-width: 1440px) {
    width: 50%;
    padding: 0;
    text-align: left;
    margin-bottom: 0;
  }
}

.headline {
  margin-top: 0;
  @media (min-width: 1440px) {
    text-align: left;
  }
}

.text {
  text-indent: 10%;
  text-align: justify;
}

.imageWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1440px) {
    width: 50%;
  }
}

.image {
  width: 100%;
  max-width: 195px;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    max-width:  385px;
  }
}
