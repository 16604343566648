.section {
  transform: translateX(0);;
  background: #67ccea;
  padding: 60px 0;
  transition: 1s;
  @media (min-width: 1440px) {
    padding: 100px 0;
  }
}

.wrapper {
  padding: 0 30px;
  @media (min-width: 768px) {
    padding: 0 65px;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.notVisible {
  opacity: 0;
  background: #67ccea;
  padding: 60px 0;
  transform: translateX(100%);
  transition: 1s;
  @media (min-width: 1440px) {
    padding: 100px 0;
  }
}